.root {
  display: flex;
  padding: 1rem 0;
}

.summary {
  padding-left: 1rem;
  text-align: center;
}

.summary .label {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 1rem;
}

.summary .table th,
.table td {
  border: 1px solid black;
}
